<template>
  <div class="container py-5 py-lg-8">
    <h2 class="content-title fs-1 text-center mb-5 mb-lg-8">合作夥伴</h2>
    <div class="px-lg-6">
      <div class="row pt-4">
        <div
          class="col-6 col-sm-4 col-lg-3 mb-3"
          v-for="item in partners"
          :key="`selectpartner${item.id}`"
        >
          <a :href="item.url" target="_blank">
            <img
              :src="$imageFilters(item.imagePath)"
              alt="合作夥伴"
              style="height: 120px"
              class="obj-fit-contain border"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiQueryUserBrand } from "@/api/brand";

export default {
  data() {
    return {
      partners: [],
    };
  },
  computed: {
    selectpartner() {
      return this.partners.find((item) => item.type === this.partnerType);
    },
  },
  methods: {
    async queryBrands() {
      try {
        const res = await apiQueryUserBrand();
        const { code, data, message } = res.data;
        if (code === 200) {
          this.partners = data.sort((a, b) =>
            a.nameEn.toLowerCase() > b.nameEn.toLowerCase() ? 1 : -1,
          );
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        // regardless
      }
    },
  },
  created() {
    this.queryBrands();
  },
};
</script>
